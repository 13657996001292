import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAcUYiVxKUEL2GLUzMAlv29drOSj_DrKow",
  authDomain: "anotherpixel-a5aa6.firebaseapp.com",
  projectId: "anotherpixel-a5aa6",
  storageBucket: "anotherpixel-a5aa6.appspot.com",
  messagingSenderId: "644921928641",
  appId: "1:644921928641:web:381ac63b1c80e2e4495d40",
  measurementId: "G-Y3SX0D3BLB"
};

function App() {
  // Check if running in the browser
  if (typeof window !== "undefined") {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }
  return (
    <div className="App">
      <HomePage/>
    </div>
  );
}

export default App;
